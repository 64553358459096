export const ChevronDown = ({ stroke = '#fff' }) => (
  <span>
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
    >
      <path d="M9.5 13.5 16 20l6.5-6.5" strokeWidth="2" />
    </svg>
  </span>
)

export const ChevronRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
    strokeWidth="2"
  >
    <path d="M10.125 7.125 15 12l-4.875 4.875" />
  </svg>
)
export default function CollapseExpandIcon({ active }: { active: boolean }) {
  return active ? <ChevronDown stroke="#fff" /> : <ChevronRight />
}
